@value colorConcrete, lightBase from '~Styles/vars/colors.css';

.title {
  margin-bottom: 8px;
}

.titleWrap {
  margin-bottom: 20px;
}

.titleItem {
  font-weight: 500 !important;
}

.label {
  margin-top: 8px;
}

.content {
  width: 720px;
  margin: 0 auto 40px;
  padding-top: 40px;
  border-top: 1px solid colorConcrete;
}

.section {
  margin-bottom: 40px;
}

.sectionTitle {
  margin-bottom: 8px;
}

.technicalItems {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.technicalItem {
  display: flex;
  width: 350px;
}

.technicalIcon {
  margin-right: 12px;
}

div.preview {
  size: 165px;
  display: inline-flex;
  margin-bottom: 20px;

  &:not(:nth-child(4n)) {
    margin-right: 20px;
  }
}

:global .isMobile,
.mobileView {
  :local .content {
    width: 100%;
    padding: 32px 16px 0;
    box-sizing: border-box;
  }

  :local .previewContent {
    position: absolute;
  }

  :local .technicalItem {
    width: 100%;
  }

  :local div.preview {
    width: calc((100% - 32px) / 3);
    height: auto;

    &::after {
      content: '';
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }

    &:not(:nth-child(4n)) {
      margin-right: 0;
    }

    &:not(:nth-child(3n)) {
      margin-right: 16px;
    }
  }
}


.textWithLinks {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;

  & a {
    width: 100%;
  }
}